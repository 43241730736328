.playlistItemContent {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.playlistItemImage {
    border-radius: var(--bannerWidgetBorderRadius, 15px);
    -webkit-border-radius: var(--bannerWidgetBorderRadius, 15px);
    -moz-border-radius: var(--bannerWidgetBorderRadius, 15px);
    -ms-border-radius: var(--bannerWidgetBorderRadius, 15px);
    -o-border-radius: var(--bannerWidgetBorderRadius, 15px);
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.playlistItemFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;
    word-wrap: break-word;
    padding-top: 1rem;
}

.playlistItemTitle {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    text-wrap: wrap;
    text-transform: uppercase;
    white-space: normal;
}

.playlistItemSubtitle {
    font-size: 14px;
    opacity: 0.75;
    white-space: normal;
}