.ModalBackground {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.ShareModal {
    background: var(--backgroundColor);
    color: var(--textColor);
    border-radius: var(--bannerWidgetBorderRadius);
    z-index: 9;
    padding: 40px 60px 20px 60px;
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SocialContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
}

.SocialIcon {
    margin: 0 !important;
}

.SocialName {
    margin-top: 1rem;
    font-size: 0.8rem;
}

.URLContainer {
    border: 1px solid dimgray;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
    padding: 6px 12px;
    overflow-wrap: anywhere;
}

.URLText {
    margin: 0 !important;
    color: dimgrey;
}

.URLTextMobile {
    margin: 0 !important;
    color: dimgrey;
    font-size: 0.8rem;
}

.CopyButton {
    color: white;
    background-color: transparent;
    border: none !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
}

.CopyButton:focus {
    outline: none !important;
}

.CopiedButton {
    color: var(--focusColor);
    background-color: transparent;
    border: none !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
}

.CopiedButton:focus {
    outline: none !important;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.CancelButton {
    width: 120px !important;
}

.react-share__ShareButton svg{
    width: 64px;
    height: 64px;
}

/* Pantallas pequenas */
@media only screen and (max-width: 768px) {
    .ShareModal {
        padding: 5%;
    }
    .URLContainer {
        flex-direction: column;
    }

}