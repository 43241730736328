.Menu .BarraSuperiorUsuario {
  padding: 0 2rem 0 60px;
  z-index: 2;
  background-image: linear-gradient(180deg, var(--backgroundColor) 10%, transparent);
}
.sticky {
  position: fixed;
}
.scrolling {
  background-color: var(--backgroundColor);
}
.Menu .MenuLateral {
  position: fixed;
  top: 0;
  width: 60px;
  height: 100%;
  overflow: hidden;
  word-wrap: break-word;
  background-color: var(--appColor);
  z-index: 23;
  box-shadow: 4px 0 8px 3px rgba(0, 0, 0, 0.2);
}

.MenuContainer {
  height: calc(100% - 30px);
  max-height: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 30px;
}

.WithMenuContainer.Nofooter {
  padding-bottom: 0;
}

.WithMenuContainer.NoScroll {
  min-height: 93vh;
}

.WithMenuContainer {
  position: relative;
  padding-left: 60px;
  padding-top: 60px;
  min-height: calc(100vh - 230px);
  padding-bottom: 40px;
  z-index: 1;
}

.MenuSection {
  height: 60px;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  color: var(--sidebarIconColor);
}

.MenuSection:hover,
.MenuSectionRes:hover,
.MenuSectionRes:hover .TextRes {
  cursor: pointer;
  color: var(--iconHoverFilter);
}

.CustomIcon:hover {
  filter: var(--iconHoverFilter);
}
.MenuSection img {
  max-height: 24px;
  max-width: 24px;
}

.MenuSection.active,
.MenuSectionRes.active {
  cursor: default;
  background-color: var(--focusColor);
  outline: none;
  box-shadow: none;
}

.MenuSection i {
  font-size: 24px;
}

.MenuSection i.fa-search,
.MenuSection i.fa-bars {
  font-size: 18px;
}

.MenuSection .SectionText {
  font-size: 12px;
  display: block;
  margin-top: -6px;
  color: red;
}

ion-icon {
  color: currentColor;
  /* TODO Cor de texto de boton primario*/
  pointer-events: none;
}


.settings-sharp > svg {
  max-height: 40px !important;
  width: auto;
  margin: 0;
}

.exit-sharp > svg {
  max-height: 40px !important;
  width: auto;
  margin: 0;
}

.MenuLateralRes .exit-sharp > svg,
.MenuLateralRes .settings-sharp > svg {
  padding-top: 0 !important;
  margin-top: 13px !important;
  min-width: 40px;
}

.MenuLateralRes .exit-sharp > .TextRes,
.MenuLateralRes .settings-sharp > .TextRes,
.MenuLateralRes .color-palette-sharp > .TextRes {
  margin-left: 10px !important;
}

#LogoutIcon {
  filter: invert(79%) sepia(64%) saturate(4480%) hue-rotate(357deg) brightness(103%) contrast(105%);
}

.Menu .MenuLateralRes {
  position: fixed;
  z-index: 23;
  background-color: var(--appColor);
  color: var(--hoverButtonTextColor);
  opacity: 1;
  height: 100%;
}

.NotificationMenuRes {
  position: fixed;
  left: 53px;
  z-index: 21;
  width: 80%;
  max-width: 400px;
  background-color: var(--backgroundColor);
  color: var(--hoverButtonTextColor);
  opacity: 1;
  height: 100%;
  overflow-y: auto;
}
.NotificationBellWrapper:hover ion-icon,
.MenuSection:hover ion-icon {
  filter: var(--iconHoverFilter);

}

.hoverable-icon {
  cursor: pointer;
}

.hoverable-icon:hover {
  filter: var(--iconHoverFilter);
}

.Menu .MenuLateralRes ion-icon {
  float: left;
  font-size: 24px;
}

.MenuSectionRes {
  display: flex;
  align-items: center;

}

.MenuSectionRes:hover ion-icon,
.MenuSectionRes:hover img,
.MenuSectionRes:hover .TextRes {
  filter: var(--iconHoverFilter);
}

.Menu .MenuLateralRes .Logo-Barra {
  margin-left: 40px !important;
}

.Menu .MenuLateralRes .MenuSection {
  cursor: default;
}

.Menu .MenuLateralResContainer {
  position: fixed;
  z-index: 20;
  height: 100%;
  top: 0;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: var(--backgroundColor);
}
.MenuLateralRes {
  width: 240px;
}

.MenuSectionRes {
  height: 60px;
  background-color: var(--appColor);
  display: flex;
  align-items: center;
  padding-left: 18px;
  color: var(--sidebarIconColor);
}

.MenuSectionRes img {
  max-height: 24px;
  max-width: 24px;
}

.TextRes {
  color: var(--hoverButtonTextColor);
  font-size: 14px;
  margin-left: 10px;
}

.cinfo-celta .TextRes {
  margin-left: 25px;
}

.PecharMenu {
  cursor: pointer;
  padding: 0 1rem;
  width: 100%;
  text-align: right;
}

.LogoMenu {
  float: left;
  margin-top: -20px;
  margin-left: -5px;
}

.MenuSectionRes.active {
  cursor: default;
  background-color: var(--focusColor) !important;
  color: var(--hoverButtonTextColor) !important;
  width: 100%;
}

.MenuSection.active:hover,
.MenuSectionRes.active:hover,
.MenuSection.active:hover ion-icon,
.MenuSectionRes.active:hover ion-icon,
.MenuSection.active:hover img,
.MenuSectionRes.active:hover img,
.MenuSection.active:hover .TextRes,
.MenuSectionRes.active:hover .TextRes {
  filter: none !important;
  color: inherit !important;
  cursor: default !important;
  background-color: var(--focusColor) !important;
}

.MenuSection.active ion-icon,
.MenuSectionRes.active ion-icon {
  filter: none !important;
  color: var(--hoverButtonTextColor) !important;
  margin: 0;
}

.NotificationsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.1rem;
  margin: 0 0.5rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 30px;
}

.Signal {
  background-color: var(--alertBackgroundColor) !important;
}

.NotificationBellWrapper {
  position: relative;
  display: inline-block;
  margin-top: 1px;
}

.NotificationBellWrapper ion-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-bottom: 7px;
  color: var(--sidebarIconColor) !important;
}

.NotificationBell {
  font-size: 24px;
  color: var(--sidebarIconColor);
}
.MenuInferiorIcons {
  margin-top: auto; /* Empuja el contenedor hacia abajo */
  margin-bottom: -6px;
  display: flex;
  flex-direction: column; /* Alineación vertical */
  padding-bottom: 6px;
}
.NotificationDot {
  position: absolute;
  top: -1px;
  right: -5px;
  width: 17px;
  height: 17px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: white;
  font-weight: bold;
}

.NotificationDoto {
  position: absolute;
  top: -2.8px;
  right: -5px;
  width: 17px;
  height: 17px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: white;
  font-weight: bold;
}

.NotificationsNumber {
  margin: 0 !important;
  color: white !important;
}

.NotificationMenuTitle {
  text-align: left;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 1.5rem;
  color: var(--textColor);
}

.NotificationMenuSubtitle {
  text-align: left;
  padding-left: 30px;
  font-size: 0.9rem;
  color: var(--textColor);
  margin: 0 !important;
}

.NotificationToggleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/*ACTIVATE NOTIFICATION SWITCH*/
.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--textColor) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--textColor) !important;
}

.AlertContainer {
  margin-top: 3rem;
  margin-left: 0.4rem;
}

.AlertCard {
  margin: 1rem 1.4rem;
  height: 120px;
  background-color: var(--footerBackgroundColor) !important;
  display: flex;
  border-radius: 8px;
  position: relative;
  border: 1px solid white;
}

.AlertCard .deleteIconMessage {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AlertCard .deleteIconMessage .Icono-trophy {
  filter: brightness(0) invert(1);
}

.Icono-trophy {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.UnreadCount {
  color: red;
  font-weight: bold;
  font-size: 1.2rem;
}

.NewCard {
  background-color: var(--hoverButtonColor) !important;
  cursor: pointer;
}

.AlertImage {
  height: 120px;
  width: auto;
  border-radius: 8px 0 0 8px;
}

.AlertTextContainer {
  text-align: left;
  padding: 0.5rem 1rem;
  margin-right: 30px;
}

.AlertTitle {
  margin-bottom: 0.2rem !important;
  font-size: 1rem;
  word-wrap: break-word;
  overflow: visible;
}

.AlertText {
  font-size: 0.8rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0 !important;
}

.NotificationsLateralContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background-color: var(--alertBackgroundColor);
  margin: 1.5rem;
  border-radius: 16px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.NotificationsLateralNumber {
  margin: 0 !important;
  font-size: 1.2rem;
}

/* Pantallas pequenas */
@media only screen and (max-width: 768px) {
  .Menu .BarraSuperiorUsuario {
    padding: 0 0 0 60px;
  }
  .WithMenuContainer {
    padding-left: 0;
    width: 100%;
    left: 0;
    min-height: calc(100vh - 230px);
  }

  .Menu .MenuLateral {
    height: 60px;
    width: 60px;
    background: none;
    color: var(--hoverButtonTextColor);
    box-shadow: none;
    position: fixed;
  }

  .Menu ion-icon {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 414px) {
  .NotificationMenuRes {
    position: fixed;
    width: 100%;
    left: 0;
    top: 70px;
  }
}
