#carousel_v2 .react-multiple-carousel__arrow--left {
  left: -4rem;
  min-width: 4rem !important;
}
#carousel_v2 .react-multiple-carousel__arrow--right {
  right: -2rem;
}
#carousel_v2 .react-multiple-carousel__arrow {
  background-color: var(--backgroundColor);
  height: 100%;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  min-width: 2rem;
}
#carousel_v2 .react-multiple-carousel__arrow:default {
  color: var(--defaultButtonTextColor) !important;
}
#carousel_v2 .react-multiple-carousel__arrow::before {
  color: var(--defaultButtonTextColor) !important;
}

@media only screen and (max-width: 768px) {
  #carousel_v2 .react-multiple-carousel__arrow--left {
    left: -0.5rem;
  }
  #carousel_v2 .react-multiple-carousel__arrow--right {
    right: -0.6rem;
  }
  #carousel_v2 .react-multiple-carousel__arrow {
    height: 10%;
    min-width: 2rem !important;
  }
}
