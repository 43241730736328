.playlistHighlightItem {
  height: 290px;
  background-size: cover;
  display: flex;
}
.overContainerHome {
  max-width: 100%;
  position: absolute;
  padding: 2rem;
  user-select: none;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: flex-end;
}

.textHome {
  gap: 0.25rem;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--textColor) !important;
}

.highlightInfoContainer {
  text-align: left;
  font-size: 14px;
}

.highlightInfoContainer > *:after {
  content: " - ";
}

.highlightInfoContainer > *:last-child:after {
  content: "";
}

.xenero {
  text-transform: capitalize;
}

.titleHighlight {
  font-family: var(--titleFont);
  color: var(--featuredWidgetTitleColor);
  font-size: 2.8rem;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

.highlightButtonsContainer {
  display: flex;
  gap: 1rem;
}

.buttonSeeVideo {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
  background: var(--hoverColor);
  border-radius: 5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
}

.buttonSeeVideo:hover {
  background-color: var(--hoverColor);
}

.buttonSeeVideo:hover a {
  color: white;
}

.buttonSeeDetails {
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 2px solid var(--textColor);
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
}

.buttonSeeDetails:hover {
  border: 2px solid #ffffff;
  background-color: white;
  transition: all ease 0.15s;
  -webkit-transition: all ease 0.15s;
  -moz-transition: all ease 0.15s;
  -ms-transition: all ease 0.15s;
  -o-transition: all ease 0.15s;
}
#infoIcon {
  font-size: 30px;
}
.playlistHighlight {
  height: 290px;
}
.playlistHighlightHover {
  cursor: pointer;
}

.playlistHighlight img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.playlistHighlightCover::before {
  content: "";
  height: 290px;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, var(--backgroundColor) 100%),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 55%, var(--backgroundColor) 100%);
}
.highlightTagsContainer {
  display: flex;
  gap: 0.25rem;
  text-transform: capitalize;
}
.tagIcon {
  margin-right: 0.5rem;
}
.buttonAccess {
  width: 100%;
  display: inline-block;
  text-align: right;
  padding-top: 40px;
}

@media only screen and (min-width: 768px) {
  .playlistHighlightItem {
    padding-left: 3%;
    height: 500px;
  }
  .playlistHighlight {
    width: 100%;
    max-height: 500px;
    height: initial;
  }
  .playlistHighlightCover::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 70%, var(--backgroundColor) 100%),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, var(--backgroundColor) 100%);
  }

  .textHome {
    gap: 2rem;
    padding-bottom: 2rem;
  }

  .overContainerHome {
    max-width: 650px;
    padding: 0 2rem 0 2rem;
    position: static;
    z-index: 0;
  }

  .buttonSeeDetails {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 425px) {
  .titleHighlight {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}

.playlistHighlight img,
.playlistHighlight video {
  height: 500px;
  width: 100%;
  object-fit: cover;
}