.metaData {
  font-size: 14px;
  color: var(--textColor);
}
.xenero {
}
.fecha {
}
.temporadas {
}
