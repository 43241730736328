.icon {
    height: 30px;
}

.FavButton {
    display: grid;
    place-content: center;
}
.FavButtonLarge {
    padding: 0.5rem 1rem; /* Espaciado interno uniforme */
    display: flex;
    align-items: center;
    justify-content: center; /* Centrado del contenido */
    gap: 0.5rem;
    border: 2px solid var(--defaultButtonBorderColor);
    border-radius: 5px; /* Bordes redondeados */
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    background-color: var(--defaultButtonColor);
    color: var(--defaultButtonTextColor);
    text-align: center;
    height: 50px; /* Altura fija para uniformidad */
    min-width: 150px;
    max-width: 100%; /* Se adapta al ancho disponible */
    box-sizing: border-box; /* Incluye relleno y borde en las dimensiones */
}
.FavButton:hover {
    cursor: pointer;
    color: var(--hoverButtonColor);
}
.FavButtonLarge:hover {
    color: var(--hoverButtonTextColor);
    background-color: var(--hoverButtonColor);
    border: 2px solid var(--hoverButtonBorderColor);
}

.IconContainer {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconContainer ion-icon {
  width: 100%;
  height: 100%;
}