.BarraSuperior {
  height: 60px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--headerBackgroundColor);
  color: var(--headerIconColor);
}
.BarraSuperiorUsuario .Usuario {
  display: flex;
  align-items: center;
}
.LogoContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 3%;
}
.LoginButton {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Logo-Barra {
  width: 130px;
  float: left;
  height: 50px;
  background: var(--logo) no-repeat center center;
  -webkit-background-size: contain !important;
  -moz-background-size: contain !important;
  -o-background-size: contain !important;
  background-size: contain !important;
}

.Register .Logo-Barra {
  margin-left: 0;
}

.groupOptions {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

.SearchBarWrapper {
  display: flex;
  align-items: center;
}

.languages {
  float: right;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.languages .MenuDesplegable {
  position: absolute;
  z-index: 9;
  right: 1rem;
  top: 60px;
  background: rgb(182 172 172 / 90%);
  width: 0;
  overflow: hidden;
  padding: 0;
  transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -ms-transition: width 0.25s ease-in-out;
  -o-transition: width 0.25s ease-in-out;
}

.MenuDesplegable.active {
  padding: 0.5rem;
  width: 300px;
  border: 1px solid white;
}
.MenuDesplegable.belowTheSearch {
  top: 120px;
}

.languages .MenuDesplegable_JP {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 60px;
  background: rgb(182 172 172 / 50%);
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: height 0.25s ease-in-out;
  -webkit-transition: height 0.25s ease-in-out;
  -moz-transition: height 0.25s ease-in-out;
  -ms-transition: height 0.25s ease-in-out;
  -o-transition: height 0.25s ease-in-out;
}

.MenuDesplegable_JP.active {
  padding: 0.5rem;
  height: auto;
  width: auto;
  border: 1px solid white;
}

.BarraSuperior .MenuDesplegable_JP .Button-Language {
  border-right: 0;
}

.displayNoneTooltip {
  display: none !important;
}

.LanguageMenuIconContainer {
  display: flex;
  color: var(--headerIconColor);
}
.LanguageMenuIconContainer:hover {
  color: var(--headerHoverIconColor);
}

#LanguageMenuIcon {
  width: 30px !important;
  height: 30px !important;
  color: var(--titleColor) !important;
}

.BarraSuperior .Button-Language {
  display: inline;
  cursor: pointer;
  border-right: 2px solid var(--textColor);
  padding-right: 15px;
  padding-left: 15px;
}
.verticalText {
  writing-mode: vertical-rl;
}
.BarraSuperior .Button-Language:last-child {
  border: none;
}

.BarraSuperior .Button-Language.Selected {
  font-weight: bold;
  cursor: default;
  color: var(--focusColor);
}

.BarraSuperior .Button-Live {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.BarraSuperior .UserSeccion {
  display: flex;
  align-items: center;
}

.BarraSuperior .MenuDesplegableContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.BarraSuperior .UserSeccion .MenuDesplegable {
  position: absolute;
  z-index: 9;
  right: 3px;
  background: var(--backgroundColor);
  font-size: medium;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 #9b9b9b;
  padding: 12px 5px 5px;
}

.MenuDesplegable .MenuSubsection {
  display: block;
  text-align: left;
  padding: 8px 20px 10px;
  min-width: 250px;
}

.MenuDesplegable .MenuSubsection:hover,
.MenuDesplegable .MenuSubsection.Selected {
  color: var(--textColor) !important;
  border-left: 3px solid var(--appColor);
  background-color: var(--hoverColor);
}

.MenuDesplegable .MenuSubsection:hover:after,
.MenuDesplegable .MenuSubsection.Selected:after {
  content: "";
  opacity: 0.2;
  display: block;
  height: 42px;
  width: calc(100% - 8px);
  position: absolute;
  margin-top: -32px;
  margin-left: -20px;
}

.BarraSuperior .AvatarMenu {
  position: relative;
  z-index: 2;
  height: 30px;
  border: 3px solid white;
  border-radius: 50px;
}

.BarraSuperiorUsuario .Usuario {
  float: right;
}

.RankingMenu {
  position: relative;
  z-index: 1;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  background: white;
  border-radius: 12px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: -14px;
  max-height: 25px;
}

.RankingMenu .TrophyMenu {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: 10px;
}

.RankingMenu .PointsMenu {
  font-size: 16px;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 768px) {
  .RankingMenu {
    margin-top: -50px;
  }
  .MenuDesplegable.active {
    width: calc(100vw - 4rem);
    right: -37px;
  }
  .BarraSuperior .UserSeccion {
    padding-top: 35px;
  }
  .RankingMenu {
    margin-top: -50px !important;
    margin-left: 2px !important;
  }
  .groupOptions {
    gap: 1rem;
    width: 25%;
  }
  .BarraSuperior .AvatarMenu {
    margin-top: -35px !important;
}
}

@media only screen and (max-width: 425px) {
  .BarraSuperior .Usuario {
    margin-right: 5px;
    float: right !important;
  }

  .BarraSuperior .UserSeccion {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 650px) {
  .Usuario .UserSeccion {
    display: none;
  }

  .RankingMenu {
    margin-top: -100px;
    z-index: 3;
    margin-left: 0;
  }
}
