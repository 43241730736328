.titulo {
  font-weight: 700;
  font-size: var(--widgetTitleFontSize);
  color: var(--bannerWidgetTitleColor);
  text-align: left;
  padding-top: 20px;
}

.frameworkTitleBackground {
  height: 140px;
  margin: -1rem 0 -2rem;
  display: flex;
  align-items: center;
  background: var(--bannerWidgetImageColor);
  background-color: var(--bannerWidgetBgColor);
  padding: 0 1rem;
}
