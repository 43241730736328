.playlistItemContainer {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  padding: 0.25rem;
  /*max-width: 340px;*/
}

.playlistItemContainer:hover .playlistHeader {
  border: 2px solid var(--hoverColor);
  border-radius: var(--bannerWidgetBorderRadius, 15px);
}

.playlistItemContainer:hover .playlistItemImage {
  opacity: 0.25;
}

.playlistItemContainer:hover .playFavContainer__top {
  opacity: 1;
}

.playlistItemFooter {
  display: grid;
  gap: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.playlistItemFooter__bottomText {
  text-align: left;
  padding: 1rem 0.25rem 0;
}

.playlistItemFooter__topText {
  position: absolute;
  justify-content: center;
  text-align: center;
  padding: 0.25rem;
  top: 0;
  opacity: 0;
  width: 100%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.playlistItemTitle {
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  white-space: break-spaces;
}
.playlistItemSubtitle {
  font-size: 14px;
  opacity: 0.75;
}
@media only screen and (min-width: 768px) {
  .playlistItemContainer:hover {
    transform: translateY(-1rem);
    -webkit-transform: translateY(-1rem);
    -moz-transform: translateY(-1rem);
    -ms-transform: translateY(-1rem);
    -o-transform: translateY(-1rem);
  }
}
