.button-text {
    padding-right: 10px;
}

.button-register {
    margin-top: 5px;
}

.Register .App-header {
    height: 8vh;
    font-weight: bold;
}

.App-container {
    padding-top: 0 !important;
    width: 80%;
}

.logo{
    padding-top: 2vh;
    padding-bottom: 25px;
}

.logo img{
    padding-top: 1vh;
    max-width: 50%;
    max-height: 100px;
}

.Register {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100vh;
    text-align: left;
    color: #fff;
    position: relative;
}

.Register-Background {
    position: absolute;
    min-height: 100vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
    top: 0;
}

.Background-Image {
    min-height: 100vh;
    min-width: 100vw;
}

.Register .App-container::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    background-size: cover !important;
    height: 100%;
    pointer-events: none;
}

.Register .App-container {
    min-height: calc(100vh - 75px);
}

.Register-Container {
    padding: 3%;
    width: 100%;
    background-color: #1D1D1F;
    min-height: 70%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
}

.WizardContainer {
    padding-top: 1rem;
    padding-bottom: 25px;
    width: 65%;
    position: relative;
    z-index: 9;
}



.popperDatePicker{
    top:-170px !important;
    left: 50% !important;
    z-index: 9;
    margin-top: 0;
}
.popperDatePicker .react-datepicker__triangle {
    display: none;
}

.languageLine {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.languageLine .sectionSubText {
    flex-grow: 1;
}

.LanguageContainer select {
        background: #000;
        color: #fff;
    }


@media only screen and (max-width: 600px) {
    .popperDatePicker {
        top: 0px !important;
        left: 0px !important;
    }
}


.Register .WizardContainer {
    min-height: 450px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.Descricion {
    font-size: 16px;
   /* padding-top: 25px; */
}
.formFirstLine{
    margin-top: 3%;
}

.Descricion h1 {
    padding-top: 0;
}
.interestsList{
    margin-top: 2rem;
}

.Wizard-Buttons {
    /* height: 80px; */
}

.Wizard-Buttons .btnPrevious{
    float: left;
}

.Wizard-Buttons .btnNext{
    float: right;
}

.Wizard-Buttons .button-link-primary {
    float: right;
    width: 120px;
}

.Wizard-Buttons .button-link-secondary {
    float: left;
    width: 120px;;
}

/* page navigation */
.Header-Title {
    font-weight: bold;
    margin: 0;
}
.Number-Pages {
    letter-spacing: 7px;
    margin-left: 15px;
}
.Page-Actual-Number {
    color: var(--focusColor);
}


/* Stepper */
.Register .MuiMobileStepper-root {
    background: transparent;
    position: absolute;
    min-width: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 55px;
    padding-bottom: 25px;
}

.Register .MuiMobileStepper-dots {
    margin: auto;
    width: 100%;
}

.Register .MuiMobileStepper-dot {
    background-color: transparent;
    flex: 1;
}

.Register .MuiMobileStepper-dot::before {
    width: 16px;
    height: 16px;
    content: "";
    display: block;
    margin: auto;
    border-radius: 50%;
    background-color: var(--backgroundColor);
}

.Register .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot {
    width: 8px;
    height: 8px;
    margin-top: 4px;
}

.Register .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot::before {
    width: 8px;
    height: 8px;
    content: "";
    display: block;
    margin: auto;
    border-radius: 50%;
    background-color: #B8b8b8;
}

/* Select Age */

.selectAgeContainer {
    min-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.App-icon {
    height: 25vmin;
    pointer-events: none;
    padding: 8vh;
}

.Register h1{
    color: #fff;
    padding-top: 0 !important;
}

.Register h2{
    padding-bottom: 2rem;
    font-weight: bold !important;
}

.selectAge{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.selectAge > .campoData {
    position: relative;
}

.agePageTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
}

.agePageTitle h3 {
    padding-bottom: 0;
    font-weight: bold !important;
}

.selectAgePregunta {
    font-size: medium;
    color: #202020;
    padding-bottom: 20px;
    flex-direction: column;
}

.buttonGroupNavigation {
    width: 60%;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

buttonGroupNavigation {
    width: 60%;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}

.noNext {
    justify-content: center;
}

.buttonGroupNavigation .button {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}



.button-primary {
    color: white;
    border-radius: 6px;
    background-color: var(--focusedButtonColor);
    border: 1px solid var(--focusedButtonBorderColor);
    cursor: pointer;
    padding: 8px 0px;
}
 .button-primary:hover {
    background: #202020 0% 0% no-repeat padding-box;
    border: 1px solid white;
}

.buttonGroupNavigation .button-secondary {
    color: white;
    border-radius: 6px;
    background-color: #202020;
    border: 1px solid white;
    cursor: pointer;
}
.buttonGroupNavigation .button-secondary a{
    color: white !important;
}
.buttonGroupNavigation .button-secondary:hover {
    background: var(--focusedButtonColor) 0% 0% no-repeat padding-box;
    border: 1px solid var(--focusedButtonBorderColor);
}
.buttonGroupNavigation a:hover {
    color: white !important;
}
.subTitle a {
    color: white !important;
}
.button.disabled {
    background-color: #1d1d1f;
    cursor: unset;
    color: #9b9b9b;
}

.button.disabled:hover {
    border: 1px solid var(--focusedButtonBorderColor);
}

/* NameUser */

.NomeUsuario {
    font-size: 16px;
    padding-top: 3vh;
    padding-bottom: 3vh;
    font-weight: bold;
}

.Register-Container .NameUser,  .Register-Container .Avatar, .Register-Container .CTitor,
.Register-Container .DataUser,  .Register-Container .Categoria, .Register-Container .Conditions,
.Register-Container .Confirm {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.Register-Container .Categoria .interestsList{
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
}

.NameUser .InputNome{
    /*margin-top: 5vh;*/
}

.NomeRandom {
    font-size: 36px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 3vh;
    text-align: center;
}

.BotonRandom {
    padding: 40px;
    text-align: center;
}

.BotonRandom img {
    cursor: pointer;
    height: 100px;
}

/* Avatar */
.AvatarsContainer {
    /*height: 50vh;
    max-height: max(calc(60vh - 200px), 180px);
    overflow: auto;*/
    width: 100%;
}

.AvatarCont {
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.AvatarCont .checked {
    display: none;
    color: #23d723;
}

.AvatarCont:not(.Selected){
    opacity: 1;
}

.RegisterAvatarImaxe.Selected {
    border: 4px solid var(--focusColor);
    border-radius: 50%;
}

.RegisterAvatarImaxe:not(.Selected){
    opacity: 0.6;
}

.AvatarCont.Selected .checked {
    display: block;
    z-index: 999;
    position: relative;
    margin-top: min(-100px, -15vh);
}

.RegisterAvatarImaxe {
    height: 100px;
    width: 100px;

}

.fileContainer {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    height: max(100px, 15vh) !important;
    width: max(100px, 15vh) !important;
}

.fileContainer input {
    position: relative !important;
    display: none;
}

.SubirImaxeIcon {
    height: 100% !important;
    margin-top: 0px !important;
    border-radius: 50% !important;
    width: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    transition: none !important;
}

/* Titor */
.IconoTitor {
    height: 100%;
    margin-top: 5vh;
    text-align: center;
}

/* Navigation */
.registerNavigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.registerNavigation .subTitle {
    font-size: 0.8rem;
    text-decoration: underline;
    margin-top: 0.5rem;
}


/* DataUser */
.Descricion {
    text-align: left;
    font-weight: bold;
}

.Descricion .subTitle {
    font-size: 0.7rem;
}

.Button-Language.button-secondary.Selected {
    color: white !important;
}

.campoForm {
    display: block;
    padding-right: 5px;
    padding-left: 2px;
}

.campoForm > span, .campoForm > label span {
    display: inline-block;
    text-align: left;
}

.campoForm input{
    margin-bottom: 10px;
}

.InputPassword {
    width: 92% !important;
}
.iconInput {
    margin-left: 2%;
    display: inline;
}

.currentPassword{
    display: none;
}

.Register label {
    margin: 0;
    font-size: medium;
    color: #202020;
}

.Register .passwordInfo {
    margin-left: 5px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.LanguageContainer {
    background-color: #1D1D1F;
    color: white;
}

.Button-Language {
    margin-top: 10px;
}

/* Categorias */
.CategoriaImaxe {
    width: 200px;
    height: 100px;
    border-radius: 16px;
}

.CategoriasContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.CategoriasContainer .checked {
    display: none;
    position: relative;
    margin-left: -150px;
    margin-right: 130px;
    color: #23d723;
    width: 20px;
}

.CategoriasContainer .Selected{
    border: 3px solid var(--focusColor);
    border-radius: 10px;
}

.ModificarUsuario .CategoriasContainer .Selected{
    border: 3px solid #000;
}

.CategoriaCont {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 20%;
    height: 150px;
    border-radius: 10px;
    margin: 2%;
}

.CategoriaTexto {
    padding-top: 10%;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
}

/* Confirmacion */

.Confirm {
    text-align: center !important;
}
.Confirm .title {
    color: var(--focusColor);
    letter-spacing: 3.3px;
    text-transform: uppercase;
    font-size: 2rem;
}
.confirmContent {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.rotateImage {
    transform: scaleX(-1);
}

.IconoConfirm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    width: 60%;
}
.IconoConfirm img {
    max-width: 100px;
}
.Confirm .welcome {
    margin-top: 2rem;
    font-weight: bold;
}

.Confirm .userName {
    color: var(--focusColor);
    font-weight: bold;
}

.Confirm .subTitle {
    font-size: 0.8rem;
}

.TituloCondicions{
    text-align: center;
}

.TituloCondicions h1{
    margin-top: 0;
}

.Conditions .CondicionsUso .legal-text {
    margin-top: 0 !important;
}

.Conditions .legal-question{
    margin-top: 0 !important;
}
.Conditions .legal-question .legal-terminos-servicio{
    color: white;
}

.Conditions .WizardContainer .Descricion {
    margin-bottom: 0;
}

.CondicionsUso{
    text-align: justify;
    margin-top: 2%;
}


@media only screen and (max-width: 960px) {
    .buttonGroupNavigation {
        width: 100%;
    }
    .WizardContainer {
        width: 90%;
    }
}


@media only screen and (max-width: 600px) {


    .Register .WizardContainer {
        min-height: 200px !important;
    }

    .Header-Title {
        font-size: 1.8rem;
    }
    .agePageTitle {
        margin-bottom: 0;
    }
    .buttonGroupNavigation {
        display: block;
        margin-top: -10px;
    }

    .buttonGroupNavigation .button {
        width: 90%;
    }

    .selectAge .button {
        width: 90%;
    }
}

@media only screen and (max-width: 560px) {
    .App-container {
        width: 100%;
    }

    .logo {
        margin-left: 3%;
    }

    .RegisterAvatarImaxe {
        height: 65px;
        width: 65px;
    }
    .CategoriaCont {
        width: 46%;
    }
    .IconoConfirm {
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (max-width: 400px) {
    .buttonGroupNavigation {
        margin-top: -10px;
    }
    .IconParty {
        display: none;
    }
    .NomeRandom {
        font-size: 25px;
    }

}

@media only screen and (max-width: 375px) {
    .RegisterAvatarImaxe {
        height: 55px;
        width: 55px;
    }

}

@media only screen and (max-width: 320px) {
    .RegisterAvatarImaxe {
        height: 50px;
        width: 50px;
    }
    .CategoriaCont {
        width: 96%;
    }
}